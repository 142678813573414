@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-[#141414] text-white;
    }

    header {
        @apply fixed top-0 z-50 flex w-full items-center justify-between px-4 py-4 transition-all lg:px-10 lg:py-6;
    }
}

@layer components {

    .loader {
        position: relative;
        transform: scale(1);
        animation: pulse 1s linear infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        10% {
            transform:  scale(1.3);
        }

        /*20% {*/
        /*    transform:  scale(0.9);*/
        /*}*/

        /*30% {*/
        /*    transform:  scale(1.2);*/
        /*}*/

        /*40% {*/
        /*    transform: scale(0.9);*/
        /*}*/

        /*50% {*/
        /*    transform:  scale(1.05);*/
        /*}*/

        /*60% {*/
        /*    transform:  scale(0.95);*/
        /*}*/

        /*60% {*/
        /*    transform:  scale(1);*/
        /*}*/

    }


    .headerLink {
        @apply cursor-pointer text-sm font-light text-[#e5e5e5] transition duration-[.4s] hover:text-[#b3b3b3];
    }

    .input {
        @apply w-full rounded bg-white text-black px-5 py-3.5 placeholder-[gray] outline-none;
    }

    .bannerButton {
        @apply flex items-center gap-x-2 rounded px-5 py-1.5 text-sm font-semibold transition hover:opacity-75 md:py-2.5 md:px-8 md:text-xl;
    }

    .rowButton {
        @apply flex items-center gap-x-1 rounded px-2 py-1 text-sm transition hover:opacity-75 md:py-2 md:px-4;
    }

    .modalButton {
        @apply flex h-11 w-11 items-center justify-center rounded-full border-2 border-[gray] bg-[#2a2a2a]/60 transition hover:border-white hover:bg-white/10;
    }

    .membershipLink {
        @apply cursor-pointer text-blue-500 hover:underline;
    }

    .planBox {
        @apply relative mx-1.5 flex h-20 w-[calc(100%/3)] cursor-default items-center justify-center rounded-sm bg-yellow-500 font-semibold shadow after:absolute after:top-full after:left-1/2 after:block after:-translate-x-1/2 after:border-8 after:border-b-0 after:border-transparent after:border-t-yellow-500 after:content-[""] md:h-32 lg:mx-8;
    }

    /* Table */
    .tableRow {
        @apply flex flex-wrap items-center font-medium;
    }

    .tableDataTitle {
        @apply w-full p-2.5 text-center text-sm font-normal text-white md:w-2/5 md:p-3.5 md:text-left md:text-base;
    }

    .tableDataFeature {
        @apply w-[calc(100%/3)] p-2.5 text-center md:w-[calc(60%/3)] md:p-3.5;
    }

    /* MUI Menu */
    .menu {
        @apply md:hidden;
    }

    /*.menu .MuiPaper-root {*/
    /*    @apply !absolute !left-0 !rounded-none !border !border-[gray] !bg-black !text-white;*/
    /*}*/

    /*.menu .MuiList-root {*/
    /*    @apply !p-0;*/
    /*}*/

    /*.menu .MuiMenuItem-root {*/
    /*    @apply !block !w-72 !py-3.5 !text-center !text-sm !font-light !text-[#b3b3b3] !transition !duration-200 first:cursor-default first:!font-normal first:!text-white hover:!bg-[#11100F];*/
    /*}*/
}